<!--注册-->
<template>
  <div class="registerDiv">
    <el-row>
      <el-col :span="20" :offset="2">
        <div class="topTitle topTitleRow">
          用户注册
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20" :offset="2">
        <el-row>
          <el-col :span="5" :offset="6">
            <div :class="activeIndex === 0 ? 'active' : 'unActive'">
              <span class="tabSpan">① 基本信息</span>
            </div>
          </el-col>
          <el-col :span="5" :offset="2">
            <div :class="activeIndex === 1 ? 'active' : 'unActive'">
              <span class="tabSpan">② 企业认证</span>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <hr>

    <el-row v-show="activeIndex === 0">
      <el-col :span="20" :offset="2">
        <el-form ref="firstForm" :model="firstForm" :rules="firstRules">
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="用户名" prop="userName">
                <el-input v-model="firstForm.userName" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="手机号" prop="userAccount">
                <el-input v-model="firstForm.userAccount" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="验证码" prop="code">
                <el-input v-model="firstForm.code" placeholder="请输入" clearable>
                  <el-button slot="append">发送验证码</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="密码" prop="userPassword">
                <el-input v-model="firstForm.userPassword" show-password autocomplete="new-password"
                          placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="请再次输入密码" prop="twoPwd">
                <el-input v-model="firstForm.twoPwd" show-password placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="firstForm.email" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <el-row v-show="activeIndex === 1">
      <el-col :span="20" :offset="2">
        <el-form ref="secondForm" :model="secondForm" :rules="secondRules">
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="单位名称" prop="entityName">
                <el-input v-model="secondForm.entityName" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="社会统一信用代码" prop="entityCode">
                <el-input v-model="secondForm.entityCode" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="地址" prop="entityAddr">
                <el-input v-model="secondForm.entityAddr" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="法定代表人" prop="entityLegal">
                <el-input v-model="secondForm.entityLegal" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="联系电话" prop="entityTel">
                <el-input v-model="secondForm.entityTel" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="经营范围" prop="businessScope">
                <el-input v-model="secondForm.businessScope" placeholder="请输入" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="colSpan" :offset="colOffset">
              <el-form-item label="营业执照" prop="orgImg" class="imgCardClass">
                <el-upload accept="image/jpeg,image/jpg,image/png"
                           :show-file-list="false"
                           :http-request="uploadMethod"
                           :action="uploadApi"
                           list-type="picture-card"
                           :on-success="uploadSuccess"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{file}">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url" alt=""
                    >
                    <span class="el-upload-list__item-actions"></span>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <el-row style="margin: 10px 0">
      <el-col :span="20" :offset="2">
        <el-button class="moreBtn" @click="clickTab(0)" v-if="activeIndex === 1">上一步</el-button>
        <el-button class="moreBtn" @click="clickTab(1)" v-if="activeIndex === 0">下一步</el-button>
        <el-button class="moreBtn" @click="saveData" v-if="activeIndex === 1">完成</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'
import { phoneRules } from '@/utils/validataRules'

export default {
  name: 'register',
  data() {
    var confirmPassword = (rule, value, callback) => {
      if (value !== this.firstForm.userPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      colSpan: 18,
      colOffset: 3,
      activeIndex: 0,
      firstForm: {
        userName: '',
        userAccount: '',
        code: '',
        userPassword: '',
        twoPwd: '',
        email: '',
      },
      secondForm: {
        entityName: '',
        entityCode: '',
        entityAddr: '',
        entityLegal: '',
        entityTel: '',
        businessScope: '',
        orgImg: ''
      },
      firstRules: {
        userName: [{ required: true, message: '请输入', trigger: 'blur' }],
        userAccount: [{ required: true, message: '请输入', trigger: 'blur' },
          { validator: phoneRules, trigger: 'blur' }],
        code: [{ required: true, message: '请输入', trigger: 'blur' }],
        userPassword: [{ required: true, message: '请输入', trigger: 'blur' }],
        twoPwd: [{ required: true, message: '请输入', trigger: 'blur' },
          { validator: confirmPassword, trigger: 'blur' }],
      },
      secondRules: {
        entityName: [{ required: true, message: '请输入', trigger: 'blur' }],
        entityCode: [{ required: true, message: '请输入', trigger: 'blur' }],
        entityAddr: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      uploadApi: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/attachment/uploadfilewithdrag',
    }
  },
  mounted () {

  },
  methods: {
    clickTab(index) {
      if (index === 1) {
        this.$refs['firstForm'].validate((valid) => {
          if (valid) {
            this.activeIndex = index;
          }
        })
      } else {
        this.activeIndex = index;
      }
    },
    uploadMethod(params) {
      const self = this;
      const file = params.file;
      const formData = new FormData();
      formData.append('file', file);
      axios({
        method: "post",
        url:  process.env.VUE_APP_HUSSAR_DEFAULT_API + "/attachment/uploadfilewithdrag",
        data: formData
      }).then(res => {
        self.uploadSuccess(res)
      }).catch(err => {
        this.$message.error('上传失败')
      })
    },
    uploadSuccess(res, file) {
      const self = this;
      if ((res.code === 10000 || res.code === 200) && res.data) {
        self.secondForm.orgImg = res.data.id;
        self.imgUpload_112AuthSrcData = `/attachment/showPicture?image=${res.data.id}`;
        self.imgUpload_112FileName = res.data.fileName;
        self.$message.success('上传成功');
      } else {
        self.$message.error('上传失败');
      }
    },
    saveData() {

    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
.registerDiv {
  text-align: center;
}
hr {
  margin-top: 30px;
  width: 80%;
}
.topTitleRow {
  margin: 30px 0;
}
.topTitle {
  font-size: 22px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: bold;
  color: #3E3A39;
  line-height: 24px;
}
.active {
  margin: 5px;

  .tabSpan {
    text-align: left;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #409eff;
    line-height: 24px;
  }
}
.unActive {
  margin: 5px;

  .tabSpan {
    text-align: left;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #8a8a8a;
    line-height: 24px;
  }
}
.imgCardClass {
  display: flex;
  flex-direction: column;
  text-align: left;

  ::v-deep .el-form-item__label {
    text-align: left;
  }

  ::v-deep .el-upload--picture-card {
    width: 350px;
    height: 200px;
    line-height: 200px;
  }
}
::v-deep .el-input-group__append button.el-button {
  color: #186cf5;
}
</style>
